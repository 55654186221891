import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Body, Headline } from '@sumup/circuit-ui';

import { ReactComponent as Image } from '../../../assets/settings.svg';

const CenteredImage = styled(Image)`
  margin-left: auto;
  margin-right: auto;
`;

const ScaNotEnabled = ({ t }) => (
  <>
    <CenteredImage />
    <Headline as="h1" size="three">
      {t('enable_mfa.title')}
    </Headline>
    <Body
      style={{
        paddingTop: 'var(--cui-spacings-mega)',
        paddingBottom: 'var(--cui-spacings-mega)',
      }}
    >
      {t('enable_mfa.subtitle')}
    </Body>
  </>
);

ScaNotEnabled.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ScaNotEnabled);
