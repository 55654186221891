import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

window.process = { env: { NODE_ENV: process.env.NODE_ENV } };

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
