import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { formatCurrency } from '@sumup/intl';
import { Body, Headline } from '@sumup/circuit-ui';

import { useAuth } from '../../../context/auth';

const StyledBody = styled(Body)`
  strong {
    font-weight: var(--cui-font-weight-bold);
  }
`;

const Payment = ({ t }) => {
  const {
    sessionData: { account },
  } = useAuth();

  return (
    <>
      <Headline size="two" as="h1">
        {t('accounts.payment_initiation')}
      </Headline>
      <Body
        style={{
          paddingTop: 'var(--cui-spacings-mega)',
          paddingBottom: 'var(--cui-spacings-mega)',
        }}
      >
        {t('accounts.payment_prompt')}
      </Body>
      <Body variant="highlight">{t('accounts.access_heading')}</Body>
      <StyledBody style={{ paddingBottom: 'var(--cui-spacings-mega)' }}>
        {account.accountName}{' '}
        <strong>
          {formatCurrency(account.balance / 100, 'en-GB', account.currency)}
        </strong>
      </StyledBody>
    </>
  );
};

Payment.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Payment);
