import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '../context/auth';

const Public = ({ component: Component, redirectTo }) => {
  const { sessionData } = useAuth();
  return !sessionData ? <Component /> : <Navigate to={redirectTo} />;
};

Public.propTypes = {
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default Public;
