import React, { useState, Suspense } from 'react';
import './services/i18n';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import { light } from '@sumup/design-tokens';
import '@sumup/design-tokens/light.css';
import '@sumup/circuit-ui/styles.css';

import { AuthContext } from './context/auth';
import Accounts from './components/Accounts';
import Payments from './components/Payments';
import Auth from './components/Auth';
import Callback from './components/Callback';
import Private from './routes/Private';
import Public from './routes/Public';
import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { ROUTES } from './constants';

const Logo = styled(LogoIcon)`
  display: block;
  margin-bottom: var(--cui-spacings-peta);
  margin-top: var(--cui-spacings-peta);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 480px;
`;

const App = () => {
  const [sessionData, setSessionData] = useState(null);

  return (
    <AuthContext.Provider value={{ sessionData, setSessionData }}>
      <ThemeProvider theme={light}>
        <Container>
          <Logo data-testid="sumup-logo" />
          <Suspense fallback={null}>
            <Router>
              <Routes>
                <Route
                  path={ROUTES.HOME}
                  element={
                    <Public component={Auth} redirectTo={ROUTES.CONSENTS} />
                  }
                  exact
                />
                <Route
                  path={ROUTES.CALLBACK}
                  element={
                    <Public component={Callback} redirectTo={ROUTES.CONSENTS} />
                  }
                  exact
                />
                <Route
                  path={ROUTES.CONSENTS}
                  element={
                    <Private component={Accounts} redirectTo={ROUTES.HOME} />
                  }
                  exact
                />
                <Route
                  path={ROUTES.PAYMENTS}
                  element={
                    <Private component={Payments} redirectTo={ROUTES.HOME} />
                  }
                  exact
                />
              </Routes>
            </Router>
          </Suspense>
        </Container>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
