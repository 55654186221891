import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Body, Headline } from '@sumup/circuit-ui';

import PendingImage from '../../../assets/pending.png';

const StyledImage = styled.img`
  max-width: 70%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`;

const NotVerified = ({ t }) => (
  <>
    <StyledImage src={PendingImage} alt="Not verified" />
    <Headline as="h1" size="three">
      {t('verification.pending.title')}
    </Headline>
    <Body
      style={{
        paddingTop: 'var(--cui-spacings-mega)',
        paddingBottom: 'var(--cui-spacings-mega)',
      }}
    >
      {t('verification.pending.subtitle')}
    </Body>
  </>
);

NotVerified.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NotVerified);
