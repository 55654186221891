import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Card,
  NotificationInline,
  ButtonGroup,
  Button,
} from '@sumup/circuit-ui';
import PropTypes from 'prop-types';

import { ROUTES, REQUEST_STATUSES } from '../../constants';
import { createConsent, cancelConsent } from '../../requests';
import { useAuth } from '../../context/auth';

import Payment from './screens/Payment';
import Permissions from './screens/Permissions';
import ScaNotEnabled from './screens/ScaNotEnabled';
import NotEnrolled from './screens/NotEnrolled';
import NotVerified from './screens/NotVerified';

const defaultPreferences = {
  accountInfo: true,
  balanceTxHistory: true,
};

const StyledCard = styled(Card)`
  width: 100%;
`;

const CenteredButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Accounts = ({ t }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [preferences, setPreferences] = useState(defaultPreferences);
  const { sessionData, setSessionData } = useAuth();
  const {
    requestId,
    accessToken,
    tokenCallback,
    transfer,
    account: { isMfaEnabled, isBankAccount, isOnboarded, isVerified },
  } = sessionData;

  const checkForErrors = useCallback(() => {
    if (!requestId || !accessToken) {
      setLoading(false);
      setErrorMessage(t('login.errors.invalid_request_id_access_token'));
    }
  }, [requestId, accessToken, t]);

  const handleApprove = () => {
    setSessionData({ ...sessionData, preferences });

    if (transfer) {
      navigate(ROUTES.PAYMENTS);
    } else {
      setLoading(true);
      checkForErrors();

      createConsent({ requestId, accessToken, preferences }).then(
        ({ status, data }) => {
          setLoading(false);
          if (status === REQUEST_STATUSES.success) {
            const callback = tokenCallback || data.tppCallback;
            window.location.assign(`${callback}?request-id=${data.requestId}`);
          }
        },
      );
    }
  };

  const handleCancel = () => {
    setLoading(true);
    checkForErrors();

    cancelConsent({ requestId }).then(({ status, data }) => {
      setLoading(false);
      if (status === REQUEST_STATUSES.success) {
        const callback = tokenCallback || data.tppCallback;
        window.location.assign(`${callback}?request-id=${data.requestId}`);
      }
    });
  };

  const getScreen = () => {
    if (!transfer) {
      return (
        <Permissions
          preferences={preferences}
          setPreferences={setPreferences}
        />
      );
    }
    if (!isVerified) {
      return <NotVerified />;
    }
    if (!isBankAccount || !isOnboarded) {
      return <NotEnrolled />;
    }
    if (!isMfaEnabled) {
      return <ScaNotEnabled />;
    }
    return <Payment />;
  };

  const getButtons = () => {
    if (!transfer || (isBankAccount && isMfaEnabled && isVerified)) {
      return (
        <ButtonGroup
          align="center"
          actions={{
            secondary: {
              children: t('common.cancel'),
              onClick: handleCancel,
            },
            primary: {
              children: t('common.approve'),
              onClick: handleApprove,
              isLoading: loading,
              loadingLabel: t('common.approve'),
            },
          }}
        />
      );
    }

    return (
      <CenteredButtonWrapper>
        <Button variant="secondary" onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
      </CenteredButtonWrapper>
    );
  };

  return (
    <>
      <StyledCard>
        {errorMessage && (
          <NotificationInline
            body={errorMessage}
            variant="danger"
            style={{ marginBottom: 'var(--cui-spacings-mega)' }}
          />
        )}
        {getScreen()}
        {getButtons()}
      </StyledCard>
    </>
  );
};

Accounts.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Accounts;
