import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Body, Headline } from '@sumup/circuit-ui';

import { ReactComponent as Image } from '../../../assets/error.svg';

const StyledBody = styled(Body)`
  padding-top: var(--cui-spacings-mega);
  padding-bottom: var(--cui-spacings-mega);
`;

const CenteredImage = styled(Image)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--cui-spacings-mega);
`;

const NotEnrolled = ({ t }) => (
  <>
    <CenteredImage />
    <Headline as="h1" size="three">
      {t('enable_business_account.title')}
    </Headline>
    <StyledBody>{t('enable_business_account.subtitle')}</StyledBody>
  </>
);

NotEnrolled.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NotEnrolled);
