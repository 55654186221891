import React, { useEffect, useState } from 'react';
import qs from 'query-string';

import Spinner from '../Spinner';
import ErrorView from '../ErrorView';
import { requestConsent } from '../../requests';
import { useAuth } from '../../context/auth';
import { REQUEST_STATUSES } from '../../constants';

import { QUERY_PARAMS } from './Constants';

const Callback = () => {
  const [error, setError] = useState(false);
  const { setSessionData } = useAuth();

  useEffect(() => {
    const queryParams = qs.parse(window.location.search);
    const code = queryParams[QUERY_PARAMS.CODE];
    const scope = queryParams[QUERY_PARAMS.SCOPE];
    const state = queryParams[QUERY_PARAMS.STATE];

    if (!code || !scope || !state) {
      setError(true);
      return;
    }

    requestConsent({ code, scope, state })
      .then(({ status, data }) => {
        if (status === REQUEST_STATUSES.success) {
          setSessionData(data);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [setSessionData]);

  return (
    <>
      {!error && <Spinner />}
      {error && <ErrorView />}
    </>
  );
};

export default Callback;
