import React from 'react';
import styled from '@emotion/styled';
import { Spinner as CircuitSpinner } from '@sumup/circuit-ui';

const StyledSpinner = styled(CircuitSpinner)`
  color: var(--cui-fg-accent);
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: var(--cui-spacings-peta);
  padding-bottom: var(--cui-spacings-peta);
`;

const Spinner = () => (
  <Container>
    <StyledSpinner size="giga" />
  </Container>
);

export default Spinner;
